import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Work from "./components/Work";
import Navbar from "./components/BurgerMenu";
import "./default.scss";
import NoiseEffect from "./components/Js/NoiseEffect";
import AnimationToggle from "./components/Toggle.js";

function App() {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [animationEnabled, setAnimationEnabled] = useState(true);
  const noiseEffectRef = useRef(null); 

  useEffect(() => {
    if (animationEnabled) {
      if (!noiseEffectRef.current) {
        const noise = new NoiseEffect();
        noise.startAnimation();
        noiseEffectRef.current = noise;
      }
    } else {
      if (noiseEffectRef.current) {
        noiseEffectRef.current.stopAnimation();
        noiseEffectRef.current.removeCanvas();
        noiseEffectRef.current = null;
      }
    }
  }, [animationEnabled]); 

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`App ${animationEnabled ? "" : "no-animation"}`}>
      <Navbar scrolled={scrolled} />
      <AnimationToggle animationEnabled={animationEnabled} setAnimationEnabled={setAnimationEnabled} />
      <div className="overlay"></div>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/work" element={<Work />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
