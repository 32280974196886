import { motion } from "framer-motion";
import { FiMoon, FiSun } from "react-icons/fi";
import "./style.scss";

const AnimationToggle = ({ animationEnabled, setAnimationEnabled }) => {
  return (
    <div className={`toggle-container ${animationEnabled ? "light" : "dark"}`}>
      <SliderToggle animationEnabled={animationEnabled} setAnimationEnabled={setAnimationEnabled} />
    </div>
  );
};

const SliderToggle = ({ animationEnabled, setAnimationEnabled }) => {
  return (
    <div className="slider-container">
      <button
        className={`toggle-button ${animationEnabled ? "selected-light" : "unselected-light"}`}
        onClick={() => setAnimationEnabled(true)}
      >
        <FiSun className="icon" />
        <span>On</span>
      </button>
      <button
        className={`toggle-button ${!animationEnabled ? "selected-dark" : "unselected-dark"}`}
        onClick={() => setAnimationEnabled(false)}
      >
              
        <FiMoon className="icon" />
        <span>Off</span>
      </button>
      <div className={`slider-background ${!animationEnabled ? "dark-mode" : "light-mode"}`}>
        <motion.span
          layout
          transition={{ type: "spring", damping: 15, stiffness: 250 }}
          className="slider"
        />
      </div>
    </div>
  );
};

export default AnimationToggle;
