const projectsData = [
  {
    id: 1,
    title: "Pesticides Report",
    image: require("../../assets/pesticides.png"),
    description:
      "It utilizes CSS for styling and data visualization libraries to display key data, providing an essential resource for stakeholders and the general public.",
    link: "https://multimedia.efsa.europa.eu/pesticides-report-2021/",
  },
  {
    id: 2,
    title: "MOMus - Museum of Contemporary Art",
    image: require("../../assets/momus.png"),
    description:
      "This project is a React-based digital presentation of the Museum of Contemporary Art, integrated with Drupal to manage and fetch data, designed to bring art to a broader audience through an engaging online experience.",
    link: " https://momus.gr/en/museum/museum-contemporary-art#museum",
  },
  {
    id: 3,
    title: "Interactive Corporate scroller",
    image: require("../../assets/efsa.png"),
    description:
      "This HTML project features an interactive corporate scroller for the European Food Safety Authority (EFSA), crafted using JavaScript and CSS to create a dynamic and engaging user experience. The platform serves as an essential resource for stakeholders and the general public, showcasing EFSA's initiatives and information in an accessible format.",
    link: " https://www.efsa.europa.eu/en/multimedia/efsa-at-a-glance#who-we-are",
  },
  {
    id: 4,
    title: "ESPON",
    image: require("../../assets/espon.png"),
    description:
      "This project is a sophisticated web platform for the European Spatial Planning Observation Network (ESPON), developed using React and integrated with Drupal for robust content management. The combination allows for dynamic data handling and enhanced user interactions, providing comprehensive spatial planning insights across Europe.",
    link: " https://www.efsa.europa.eu/en/multimedia/efsa-at-a-glance#who-we-are",
  },
  {
    id: 5,
    title: "DRV Finder",
    image: require("../../assets/drv.png"),
    description:
      "The DRV Finder is an innovative web application designed to assist healthcare professionals and researchers in accessing Dietary Reference Values with ease. Developed using Angular, this platform offers a highly interactive and user-responsive interface, ensuring efficient navigation and data retrieval.",
    link: "https://multimedia.efsa.europa.eu/drvs/index.htm",
  },
  {
    id: 6,
    title: "Hypertech",
    image: require("../../assets/Hypertech.png"),
    description:
      "I was part of Hypertech's website redesign project, which was built using Next.js. My role focused on animations and contributing to the overall frontend redesign.",
    link: "https://www.hypertech.gr/",
  },
];

export default projectsData;
