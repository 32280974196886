import React from "react";
import "./style.scss";
import { motion } from "framer-motion";
import projectsData from "../Js/Data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"; // Import correct FontAwesome icon

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.1,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.1,
  },
};

const pageTransition = {
  type: "spring",
  stiffness: 100,
  damping: 15,
  duration: 1,
};

const Work = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="work"
    >
      <h1>My work</h1>
      <div className="work-container">
          {projectsData.map((project) => (
            <div className="project-card" key={project.id}>
              <div className="project-image-wrapper">
                <div className="overlay"></div>
                <img
                  src={project.image}
                  alt={project.title}
                  className="project-image"
                  loading="lazy"
                />
                <div className="project-hover">
                  <div className='project-title'>
                      <h2>{project.title}</h2>
                      <p>{project.description}</p>
                  </div>
                  <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">
                    More <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="arrow-icon" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
    </motion.div>
  );
};

export default Work;
