import React from "react";
import { Link } from "react-router-dom";
import "./style.scss"; // Ensure this imports your global styles
import { motion } from "framer-motion";

const pageVariants = {
  initial: {
    opacity: 0, // Start invisible
    scale: 0.1, // Shrinks from the center
  },
  in: {
    opacity: 1, // Fully visible
    scale: 1, // Expand to normal size
  },
  out: {
    opacity: 0, // Fade out
    scale: 0.1, // Shrink to the center again
  },
};

const pageTransition = {
  type: "spring", // Smooth spring-like transition
  stiffness: 100,
  damping: 15,
  duration: 1,
};

const BubbleText = ({ text }) => {
  return (
    <>
      {text.split("").map((char, idx) => (
        <span className="hoverText" key={idx}>
          {char}
        </span>
      ))}
    </>
  );
};

const Home = () => {
  return (
    <div className="home">
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className="content"
      >
        <div className="wrap">
          <div className="container">
            <div className="row">
              <div className="home-inner">
                <h1>
                  Hey, I'm <span className="glitch">Tina</span>
                </h1>
                <p>
                  <BubbleText text="A Front End focused Web Developer" />
                </p>
                <p className="description">
                  <BubbleText text="turning ideas to real life products is my calling" />
                </p>
                <div className="link-wrapper">
                  <Link to="/work" className="arrow-link">
                    My work
                  </Link>
                  <Link to="/about" className="arrow-link">
                    More about me
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
