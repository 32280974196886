import React from "react";
import "./style.scss";
import { motion } from "framer-motion";
import MeImage from "../../assets/me.png";
import resume from "../../assets/Stamatia Makri - FRONT END DEVELOPER Resume.pdf";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { calculateYearsExperience } from '../Js/general';
import {
  faReact,
  faJs,
  faHtml5,
  faCss3,
  faSass,
  faAngular,
  faDrupal,
  faPhp,
  faGithub,
  faDocker,
  faBootstrap,
  faJira
} from '@fortawesome/free-brands-svg-icons';

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.1,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.1,
  },
};

const pageTransition = {
  type: "spring",
  stiffness: 100,
  damping: 15,
  duration: 1,
};

const About = (props) => {
  const yearsExperience = calculateYearsExperience(2022);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="about"
    >
      <div className="container wrapping">
        <div className="row">
          <div className="about-inner">
            <h1>About</h1>
            <p>
              Welcome to my portfolio! I’m Tina, a dedicated front-end developer
              with a deep passion for crafting beautiful and functional user
              interfaces. With a keen eye for design and a commitment to
              creating responsive, user-friendly experiences, I bring visions to
              life on the web.
            </p>
            <p id="experience">
              With {yearsExperience} years of professional experience, I’ve honed my skills in technologies such as React, HTML, CSS, Angular, SASS, Drupal, Next.js, C++, and more. I specialize in transforming designs into pixel-perfect, high-quality code, ensuring seamless alignment with the intended vision. My expertise bridges the gap between design and development, creating engaging digital experiences that are both functional and visually appealing.
            </p>

            <p>
              From a young age, I was fascinated by technology and the endless
              possibilities it offers to connect people and ideas. This
              fascination turned into a professional pursuit as I delved into
              the world of web development, specializing in front-end
              technologies. I thrive on the challenge of turning complex
              problems into intuitive solutions, using a blend of creativity and
              technical expertise.
            </p>
            <p>
              Most of what you see in this portfolio is the result of teamwork.
              It's very rare these days to create anything truly great all on
              your own. I deeply value collaboration and the dynamic exchange of
              ideas with others, which often leads to extraordinary outcomes.
              I'm passionate about working together with clients and colleagues
              to harness collective strengths, making every project not just a
              task, but a shared journey towards something exceptional.
            </p>
            <div className="CV">
              <a
                className="arrow-link"
                href={resume}
                download="MAKRI-STAMATIA_RESUME.pdf"
              >
                Download CV
              </a>
            </div>
            <h2>My Skills</h2>
           <div className="skills">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faReact} />
                <span className="tooltip">React</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faJs} />
                <span className="tooltip">JavaScript</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faHtml5} />
                <span className="tooltip">HTML5</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faCss3} />
                <span className="tooltip">CSS3</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faSass} />
                <span className="tooltip">SASS</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faAngular} />
                <span className="tooltip">Angular</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faDrupal} />
                <span className="tooltip">Drupal</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faPhp} />
                <span className="tooltip">PHP</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faGithub} />
                <span className="tooltip">GitHub</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faDocker} />
                <span className="tooltip">Docker</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faBootstrap} />
                <span className="tooltip">Bootstrap</span>
              </div>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faJira} />
                <span className="tooltip">Jira</span>
              </div>
            </div>

          </div>
          <div className="img-wrapper">
            <div className="img-inner-wrapper">
              <img src={MeImage} alt="Me" className="img-fluid" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
